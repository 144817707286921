.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height:100%;
  width:100%;
  background-color: #1A1A1D;
  padding: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #F9f9f9f9;
  border-radius: 8px;
  height: 100%;
  width: 500px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .outerContainer {
    height: 100%;
  }

  .container {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 480px) and (max-width: 1200px) {
  .container {
    width: 500px;

  }
}