
  .SRIcontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .outerContainer {
      height: 100%;
    }

    .container {
      width: 100%;
      height: 100%;
    }
    .SRIcontainer {
      height: 100%;
      width: 100%;
    }

  }

  @media (min-width: 480px) and (max-width: 1200px) {
    .container {
      width: 500px;

    }
    .SRIcontainer {
      height: 100%;
      width: 100%;
    }
  }
