
  .input {
    border: none;
    border-radius: 0 0 0 8px;
    width: 80%;
    height: 100%;
    font-size: 1.0em;
    margin-right: 0;
    padding: 10px 0px 10px 10px;
  }

  input:focus, textarea:focus, select:focus{
    outline: none;
  }

  .sendButton {
    color: #003752 !important;
    text-transform: uppercase;
    text-decoration: none;
    background: transparent;
    margin-top: 0px;
    /* padding: 20px;
    display: inline-block; */
    border: none;
    border-radius: 0 0 8px 0;
    width: 10%;
    font-size: 32px;
    text-align: center;
    padding: 0px 10px;
    font-size: 24px;
  }
  .sendButton :hover{
    color: #006ba0 !important;

  }

  .uploadImageBtn{
 display:none
  }

  #labelUploadImg{

    color: #003752 !important;
    text-transform: uppercase;
    text-decoration: none;
    background: transparent;
    margin-top: 8px;
    /* padding: 20px;
    display: inline-block; */
    border: none;
    border-radius: 0 0 8px 0;
    width: 10%;
    font-size: 24px;
    text-align: center;
    padding: 0px 10px;


  }

  #labelUploadImg:hover{
    color:  #006ba0 !important;
  }

  .inputBox{
    border-top:1px solid #bebebef9;
    display: flex;
  }
