
.containerRating{
  padding: 10px;
  background-color: #F3F3F3;
  border-radius: 25px;
  margin: 10px;

}

.titleRating{
  display: flex;
  padding: 5px;
  /* border: 1px solid rgb(0, 55, 82,0.2); */
  border-radius: 6px;
}
.titleRating h4{
  margin-right: 5px;
}

.rating{
  position:relative;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 20%;
}
.rating input{
  display: none;
}

.rating label{
  display: block;
  cursor: pointer;
  width: 50px;
  color:#101010;
  position: relative;
  justify-content: center;
}



.rating label:before{
  content: '\f005';
  font-family: fontAwesome;
  position: relative;
  display: block;
  font-size: 30px;
  color: #101010;
}
.rating label:after{
  content: '\f005';
  font-family: fontAwesome;
  position: relative;
  display: block;
  font-size:30px;
  color: rgb(218, 165, 32);
  top: 0;
  opacity: 0;
  transition: .5s;
  text-shadow: 0 2px 5px rgba(0,0,0,.5);
  margin-top: -30px;
  margin-bottom: 10px;
}

.rating label:hover:after,
.rating label:hover ~ label:after,
.rating input:checked ~ label:after{
    opacity: 1;

}


.BtnSendRating{
    color:rgb(255, 255, 255);
    cursor: pointer;
    background-color: rgb(0, 55, 82);
    border-radius: 6px 6px 6px 6px;
    text-align: center;
    text-decoration: none;
    padding: 5px;
    box-shadow: 0px 2px 0px 3px rgb(0, 48, 71);
    text-transform:uppercase;
    font-weight:700;
    transition: all .1s linear;
    border: none;
  }
  .BtnSendRating:active {
    box-shadow: 0 0px 0  #1b0303;
    /* border: 1px solid var(--preto); */
    transform: translateY(3px);
  }

