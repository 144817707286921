.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #003752;
    border-radius: 4px 4px 0 0;
    height: 60px;
    width: 100%;
  }

  .leftInnerContainer {
    flex: 0.5;
    display: flex;
    align-items: center;
    margin-left: 5%;
    color: white;
  }
  .rightInnerContainer {
    display: flex;
    flex: 0.5;
    justify-content: center;

  }
  .rightInnerContainer a{
    color: ivory;
    cursor: pointer;
    background-color: rgb(136, 0, 0,1);
    border-radius: 6px 6px 6px 6px;
    text-align: center;
    text-decoration: none;
    padding: 5px;
    box-shadow: 0px 3px 4px 0px rgb(68, 0, 0);
    text-transform:uppercase;
    font-weight:700;
    transition: all .1s linear;
    margin-right: 10px;
  }
  .rightInnerContainer a:active {
    box-shadow: 0 0px 0  #1b0303;
    transform: translateY(3px);
  }
  .rightInnerContainer a:hover {
    color: rgb(201, 201, 201);
  }

  .rightInnerContainer button{
    background:#003752;
    color: var(--branco);
    border-radius:6px;
    border:1px solid var(--branco);
    text-transform:uppercase;
    font-weight:700;
    padding: 3px;
    transition: all .1s linear;
    vertical-align: middle;
    box-shadow: 0px 3px 4px 0px rgb(17, 17, 17)
    /* width: 60px;
    height: 30px; */
  }

  .rightInnerContainer button:hover{
    background:var(--branco);
    color:  #003752;
    border:1px solid #003752;
  }

  .onlineIcon {
    margin-right: 5%;
  }
