.messageBox{
    background: #FFFFFF;
    border-radius: 0px 20px 20px 20px;
    padding: 5px 20px;
    color: white;
    display: inline-block;
    max-width: 80%;
    word-wrap: break-word;
    border:0.5px solid rgb(0, 55, 82,0.8 );
    box-shadow: 1px 1px 1px rgba(61, 61, 61, 0.3);

}


.currentMessageBox{
    background:  rgb(0, 55, 82);
    border-radius: 20px 0px 20px 20px;
    padding: 5px 20px;
    color: white;
    display: inline-block;
    max-width: 80%;
    word-wrap: break-word;
    border:0.5px solid rgb(0, 0, 0);
    box-shadow: 1px 1px 1px rgba(61, 61, 61, 0.3);
}

/* .currentMessageBox audio::-webkit-media-controls-panel{
  background-color:rgb(0, 55, 82);
  border-color: #003752;
} */

.messageAdminBox{
    background: #E1F2FB;
    border-radius: 20px;
    padding: 5px 20px;
    color: white;
    display: inline-block;
    max-width: 80%;
    word-wrap: break-word;
    box-shadow: 2px 2px 2px rgb(0, 0, 0, 0.3);
}
.messageText{
    width: 100%;
    letter-spacing: 0;
    float: left;
    font-size: 1.1em;
    word-wrap: break-word;
}
.messageAdminText{
    width: 100%;
    letter-spacing: 0;
    float: left;
    font-size: 0.9em;
    word-wrap: break-word;

}

.messageText img{
    vertical-align: middle;

}

.messageContainer{
    display: flex;
    justify-content: center;
    padding: 0 5%;
    margin-top: 3px;
}

.sentText{
display: flex;
align-items: center;
font-family: Helvetica;
color: #828282;
letter-spacing: 0.3px;
}

.pl-10{
    padding-left: 10px;
}

.pr-10{
    padding-right: 10px
}

.justifyStart{
    justify-content: flex-start;
}

.justifyEnd{
    justify-content: flex-end;

}

.colorWhite{
    color: white;

}

.colorDark{
    color: #353535;
}

.backgroundBlue{
    background:#003752;

}
.backgroundLight{
    background: #F3F3F3;

}

.imageBox{

    text-align: left;


}
.imageChat{
    width: 100%;

}
