
  .inputSRI{
    border: none;
    border-radius: 0 0 0 8px;
    width: 77%;
    height: 100%;
    font-size: 1.0em;
    margin-right: 0;
    padding: 10px 0px 10px 10px;
  }

  input:focus, textarea:focus, select:focus{
    outline: none;
  }

  .sendButtonSRI {
    color: #003752;
    text-transform: uppercase;
    text-decoration: none;
    background: transparent;
    margin-top: 3px;
    /* margin-right:15px;
    margin-left: 15px; */
    /* padding: 20px;
    display: inline-block; */
    border: none;
    border-radius: 0 0 8px 0;
    /* width: 10%; */
    font-size: 24px;
    text-align: center;
    /* padding: 5px 10px; */
  }

  .uploadImageBtn{
 display:none
  }

  #labelUploadImgSRI{

    color: #003752;
    text-transform: uppercase;
    text-decoration: none;
    background: transparent;
    margin-top: 4.5px;
     /* margin-right:10px; */
    /* padding: 20px;
    display: inline-block; */
    border: none;
    border-radius: 0 0 8px 0;
    /* width: 10%; */
    font-size: 27px;
    text-align: center;
    /* padding: 5px 10px; */


  }

  #labelUploadImgSRI:hover{
    color:  #006ba0 !important;

  }

  .sendButtonSRI:hover {
    color:  #006ba0 !important;

  }

  .inputBoxSRI{
    border-top:1px solid #bebebef9;
    display: flex;

  }




